import { FC } from "react";
import { css } from "@styles/css";

export const Logo: FC = () => (
  <div
    className={css({
      position: "relative",
      _hover: {
        '& h1 span': {
          color: 'accents.strong'
        }
      },
    })}
  >
    <h1>
      <span
        className={css({
          color: "accents.inverse",
          transition: "color 0.3s ease-in-out"
        })}
      >
        Eve
      </span>
      Oracle
    </h1>
    <div
      className={css({
        width: "100%",
        height: "3px",
        position: "absolute",
        bottom: "5px",
        left: "0",
        background:
          "linear-gradient(to right, rgb(237, 34, 36), rgb(243, 91, 34), rgb(249, 150, 33), rgb(245, 193, 30), rgb(241, 235, 27) 27%, rgb(241, 235, 27), rgb(241, 235, 27) 33%, rgb(99, 199, 32), rgb(12, 155, 73), rgb(33, 135, 141), rgb(57, 84, 165), rgb(97, 55, 155), rgb(147, 40, 142))",
      })}
    >
      &nbsp;
    </div>
  </div>
);
