import { Link } from "@remix-run/react";
import { FC } from "react";
import { css } from "@styles/css";
import { Logo } from "@components/logo";
import { Container } from "@components/container";
import { AccountMenu } from "../molecules/AccountMenu";

export const Header: FC = () => (
  <Container>
    <div
      className={css({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 8,
      })}
    >
      <Link to="/" className={css({ textStyle: "logo" })}>
        <Logo />
      </Link>
      <AccountMenu />
    </div>
  </Container>
);
