import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { ErrorBoundaryComponent } from "@remix-run/react/dist/routeModules";
import { useRouteError, isRouteErrorResponse } from "@remix-run/react";

export const DevErrorBoundary: ErrorBoundaryComponent = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <div className="text-left mt-8">
        <h1 className="text-4xl">
          {error.status} {error.statusText}
        </h1>
      </div>
    );
  } else if (error instanceof Error) {
    return (
      <div className="text-left mt-8">
        <h1 className="text-4xl">Error: {error.message}</h1>
        <h2 className="text-2xl">Stack trace: </h2>
        <pre className="text-lg">{error.stack}</pre>
      </div>
    );
  } else {
    return (
      <div className="text-left mt-8">
        <h1 className="text-4xl">Unknown Error</h1>;
      </div>
    );
  }
};

export const ProdErrorBoundary: ErrorBoundaryComponent = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <div className="text-left mt-8">
        <h1 className="text-4xl mt-8">
          {error.status} {error.statusText}
        </h1>
        <p className="text-lg">{error.data}</p>
      </div>
    );
  } else {
    return (
      <div className="text-left mt-8">
        <h1 className="text-4xl">Unknown Error</h1>
      </div>
    )
  }
};

export const getErrorBoundary = () =>
  process.env.NODE_ENV === "development" ? DevErrorBoundary : ProdErrorBoundary;
