import { Link, useLoaderData, useLocation } from "@remix-run/react";
import { LogOut, Settings2 } from "lucide-react";
import { FC, useState } from "react";
import { EveAccount } from "~/services/auth.server";
import { css } from "~/styles/css";
import { Flex, HStack } from "~/styles/jsx";
import { Text } from "@components/text";
import { Menu } from "@components/menu";


const LoginLink = () => {
  const { pathname } = useLocation();
  return (
    <Link to={`/auth/login?returnTo=${pathname}`}>
      <img src="https://web.ccpgamescdn.com/eveonlineassets/developers/eve-sso-login-black-small.png" />
    </Link>
  );
};

const AccountPreview: FC<{
  account: EveAccount;
}> = ({ account }) => {
  const { pathname } = useLocation();

  const [isOpen, setOpen] = useState(false);
  const close = () => setOpen(false);

  return (
    <Menu.Root
      open={isOpen}
      onSelect={close}
      onInteractOutside={close}
      onEscapeKeyDown={close}
      positioning={{
        placement: 'bottom-end',
        offset: {
          crossAxis: -32
        }
      }}
    >
      <Menu.Trigger asChild>
        <Flex
          onMouseEnter={() => setOpen(true)}
          alignItems="center"
          className={css({
            background: "bg.muted",
            borderRadius: 8,
            borderColor: "fg.subtle",
            borderWidth: 2,
            paddingX: 2,
            paddingY: 1,
          })}
        >
          <img
            src={`https://images.evetech.net/characters/${account.CharacterID}/portrait`}
            className={css({
              height: 8,
              width: 8,
              mr: 2,
              borderRadius: 8,
            })}
          />
          <Text fontWeight="bold" color="fg.default">
            {account.CharacterName}
          </Text>
        </Flex>
      </Menu.Trigger>
      <Menu.Positioner data-pl
      >
        <Menu.Content onMouseLeave={close}>
          <Menu.ItemGroup>
            <Menu.ItemGroupLabel>Account</Menu.ItemGroupLabel>
            <Menu.Separator />
            <Menu.Item value="settings">
              <HStack gap="2">
                <Settings2 /> Settings
              </HStack>
            </Menu.Item>

            <Link to={`/logout?redirectTo=${pathname}`}>
              <Menu.Item value="logout">
                <HStack gap="2">
                  <LogOut /> Log out
                </HStack>
              </Menu.Item>
            </Link>
          </Menu.ItemGroup>
        </Menu.Content>
      </Menu.Positioner>
    </Menu.Root>
  );
};

export const AccountMenu: FC = () => {
  const { account } = useLoaderData<{
    account: EveAccount;
  }>();

  return account?.CharacterID ? (
    <AccountPreview account={account} />
  ) : (
    <LoginLink />
  );
};
