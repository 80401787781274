import { cssBundleHref } from "@remix-run/css-bundle";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  json,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration } from
"@remix-run/react";

import { getErrorBoundary } from "@components/ErrorBoundary";
import stylesheet from "./index.css";
import { Header } from "@components/Header";
import { css } from "./styles/css";
import { Container } from "@components/container";
import { makePublicLoaderData, authenticator } from "./services/auth.server";

const stylesheets = [
{ rel: "stylesheet", href: stylesheet },
{
  rel: "stylesheet",
  href: "https://fonts.googleapis.com/css2?family=Barlow&family=Josefin+Sans:wght@700&display=swap"
}];


export const links: LinksFunction = () => [
...(cssBundleHref ?
[{ rel: "stylesheet", href: cssBundleHref }, ...stylesheets] :
stylesheets)];


export default function App() {

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script
          async
          src="https://eu.umami.is/script.js"
          data-website-id="e13912e4-6b69-44fa-b3f1-6b8b5d4fd284" />
        
        <Meta />
        <Links />
      </head>

      <body
        className={css({
          backgroundColor: "background.subtle",
          color: "fg.subtle",
          paddingY: 8
        })}>
        
        <Header />
        <Container>
          <Outlet />
        </Container>
        {/* <Footer /> */}
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export async function loader({ request }: LoaderFunctionArgs) {

  return json({
    ...makePublicLoaderData(await authenticator.isAuthenticated(request))
  });
}

export const ErrorBoundary = getErrorBoundary();